import React from "react"
import { FixedObject, FluidObject } from "gatsby-image"
import Img from "gatsby-image/withIEPolyfill"

import { ImageStyled } from "./styles"

interface IProps {
  fluid?: FluidObject
  fixed?: FixedObject
  objectPosition?: string
  alt?: string
}

const Image: React.FC<IProps> = ({ fluid, fixed, objectPosition, alt }) => (
  <ImageStyled>
    {fluid && (
      <Img
        fluid={fluid}
        objectFit="cover"
        objectPosition={objectPosition}
        fadeIn={false}
        loading="eager"
        alt={alt}
      />
    )}
    {fixed && (
      <Img
        fixed={fixed}
        objectFit="cover"
        objectPosition={objectPosition}
        alt={alt}
      />
    )}
  </ImageStyled>
)

export default Image
