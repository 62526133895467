import styled from "styled-components"

const ImageStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .gatsby-image-wrapper img {
    -webkit-transition: opacity 0.5s ease 0s !important;
    transition: opacity 0.5s ease 0s !important;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  /* .gatsby-image {
    width: 100%;
    height: 100%;
  }
  .gatsby-image > img {
    display: none;
  }
  .gatsby-image picture img {
    object-fit: cover;
    object-position: center;
  } */
`

export { ImageStyled }
